import axios from '../../../apis/apiclient';
import {
	REPOSITORY_DIRECTORY_CREATED,
} from '../../../actions/types';

export default (values, dispatch, props) => {
	console.log(props);
	const url = `repositories/${props.repositoryId}/items/${props.itemId}/create`;
	const directory_path = `${props.directory}${values.name}`;
	axios.post(url, {
		directory_path: directory_path,
	}).then(() => {
		dispatch({
			type: REPOSITORY_DIRECTORY_CREATED,
			payload: values.name,
		});
		window.$('#repository-add-directory-modal-form').modal().modal('hide');
	});
};