import React from 'react';
import {connect} from 'react-redux';
import {Router, Route, Switch} from 'react-router-dom';

import Category from './Category/Category';
import Header from './Header';
import Infrastructures from './Infrastructure/Infrastructures';
import InfrastructureDetail from './Infrastructure/InfrastructureDetail';
import SidebarNav from './Navigation/SidebarNav';
import UserProfile from './UserProfile';
import CategoryItemDetails from './Category/Item/CategoryItemDetails';
import Table from './Table/TableContainer';

import history from '../history';
import PrivateRoute from './Auth/PrivateRoute';

import {logout} from '../actions/authActions';
import {changeSidebarVisibility} from '../actions/sidebar';
import RepositoryLogin from './Repository/RepositoryLogin';
import RepositoryRoute from './Auth/RepositoryRoute';
import Repositories from './Repository/Repositories';
import RepositoryItems from './Repository/RepositoryItems';
import RepositoryFolder from './Repository/RepositoryFolder';



class Dashboard extends React.Component {

	toggleSidebarVisibility = () => {
		this.props.changeSidebarVisibility(!this.props.sidebarVisible);
	};

	onLogout = () => {
		this.props.logout();
	};

	render() {
		if (!this.props.isLoggedIn) {
			return null;
		}

		//TODO Add Infrastructure routes
		return (
			<Router history={history}>
				<div>
					<div className='ui dashboard'>
						<Header onSidebarButtonClick={this.toggleSidebarVisibility} onLogout={this.onLogout}/>
						<div id='app-body' onClick={this.props.sidebarVisible ? this.toggleSidebarVisibility : null}>
							<SidebarNav menuVisibility={this.props.sidebarVisible}/>
							<div className={`overlay ${this.props.sidebarVisible ? 'visible' : ''}`}/>
							<div className='pusher'>
								<div>
									<Switch>
										<Route path='/' exact component={UserProfile}/>
										<PrivateRoute path='/login-repository' exact component={RepositoryLogin}/>
										<PrivateRoute path='/infrastructures' exact component={Infrastructures}/>
										<PrivateRoute path='/infrastructures/:id' exact component={InfrastructureDetail}/>
										<PrivateRoute path='/infrastructures/:id/table/:table_id' exact component={Table}/>
										<PrivateRoute path='/infrastructures/:id/category/:category' exact component={Category}/>
										<PrivateRoute path='/repositories' exact component={Repositories}/>
										<PrivateRoute path='/repositories/:id' exact component={RepositoryItems}/>
										<PrivateRoute path='/repositories/:id/:item_id' exact component={RepositoryFolder}/>
									</Switch>
								</div>
							</div>
						</div>
					</div>
					<Route path='/infrastructures/:id/category/:category/:categoryItemId' exact
						   component={CategoryItemDetails}/>
				</div>
			</Router>
		);
	}
}

const mapStateToProps = ({auth, sidebar}) => {
	return {
		isLoggedIn: auth.isLoggedIn,
		sidebarVisible: sidebar.visibility,
	};
};

export default connect(mapStateToProps, {logout, changeSidebarVisibility})(Dashboard);