import React from 'react';
import {connect} from 'react-redux';
import Card from '../Utility/Card';
import NavigationArrow from '../Navigation/NavigationArrow';
import {onHover, selectInfrastructure} from '../../actions/infrastructureActions';
import {updateMenuItems} from '../../actions/menuItemActions';
import {unselectDateTag} from '../../actions/dateTagActions/dateTag';

class InfrastructureCards extends React.Component {

	constructor(props) {
		super(props);
		this.scrollRef = React.createRef();
	}

	onCardHover = (infrastructureId) => {
		this.props.onHover(infrastructureId);
	};

	onClickNavigation = (type) => {
		this.scrollRef.current.scrollLeft = (type === 'left' ? this.scrollRef.current.scrollLeft - 312 : this.scrollRef.current.scrollLeft + 312);
	};

	render() {
		const cards = Object.values(this.props.infrastructures).map(infrastructure => {
			return (
				<Card key={infrastructure._id} id={infrastructure._id} title={infrastructure.property_owners}
					  description={infrastructure.location.address} img={infrastructure.image_url}
					  redirectURL={`/infrastructures/${infrastructure._id}`} onHover={this.onCardHover}
					  onClick={this.props.onInfrastructureClick}
				/>
			);
		});

		return (
			<div className='container-wrapper'>
				<div className='scrolling-wrapper'>
					<NavigationArrow type='left' onClicked={this.onClickNavigation}/>
					<div className='scroll-horizontal' ref={this.scrollRef}>
						{cards}
					</div>
					<NavigationArrow type='right' onClicked={this.onClickNavigation}/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		infrastructures: state.infrastructures,
		menuItems: Object.keys(state.menuItems)
	};
};

export default connect(mapStateToProps, {onHover, updateMenuItems, selectInfrastructure, unselectDateTag})(InfrastructureCards);