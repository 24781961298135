import {maps} from '../../apis/config';

import React from 'react';
import {connect} from 'react-redux';
import {fetchInfrastructures} from '../../actions/infrastructureActions';
import {updateMenuItems} from '../../actions/menuItemActions';
import InfrastructuresMap from './InfrastructuresMap';

class Infrastructures extends React.Component {
	componentDidMount() {
		this.props.fetchInfrastructures();
		if (this.props.menuItems) {
			this.props.updateMenuItems('infrastructures', this.props.menuItems);
		}
	}

	render() {
		return (
			<InfrastructuresMap
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${maps.API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
				loadingElement={<div style={{height: '100%'}}/>}
				containerElement={<div style={{height: '94.6vh'}}/>}
				mapElement={<div style={{height: '100%'}}/>}
			/>
		);
	}
}

const mapStateToProps = ({infrastructures, menuItems}) => {
	return {
		infrastructures: Object.values(infrastructures),
		menuItems: Object.keys(menuItems)
	};
};

export default connect(mapStateToProps, {fetchInfrastructures, updateMenuItems})(Infrastructures);

