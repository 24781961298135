import React from 'react';

const DropdownMenu = (props) => {
	if (!props.items) {
		return null;
	}

	const menuItems = props.items.map(item => {
		return (
			<div key={item} className='item' onClick={() => props.onItemClick(item)}>
				{item}
			</div>
		);
	});

	return (
		<div
			className={`menu transition ${props.visibility ? 'animating slide in visible' : 'hidden'}`}>
			{menuItems}
		</div>
	);
};

export default DropdownMenu;