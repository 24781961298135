import React, {Component} from 'react';
import {ContextMenu, MenuItem} from 'react-contextmenu';

class DirectoryContextMenu extends Component {

	handleRename = () => {
		this.props.onRename();
	};

	handleDelete = () => {
		this.props.onDelete();
	};

	handleDownload = () => {
		this.props.onDownload();
	};

	render() {
		return (
			<ContextMenu id={this.props.id}>
				<MenuItem onClick={this.handleDownload}>
					Download
				</MenuItem>
				{/*<MenuItem onClick={this.handleRename}>*/}
				{/*	Rename*/}
				{/*</MenuItem>*/}
				<MenuItem onClick={this.handleDelete}>
					Delete
				</MenuItem>
			</ContextMenu>
		);
	}
}

export default DirectoryContextMenu;
