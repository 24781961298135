import {
	FETCH_TABLES,
	FETCH_TABLE_ITEMS,
	ADD_INFRASTRUCTURE_MENU_ITEMS,
} from './types';

import axios from '../apis/apiclient';

export const fetchTableItems = (infrastructureId , table_id) => async dispatch => {
	const tableItems = await axios.get(`/infrastructures/${infrastructureId}/tables/${table_id}/items`);

	dispatch({type: FETCH_TABLE_ITEMS , table_id: table_id , payload: tableItems.data});
};


export const fetchTables = (infrastructureId, date) => async dispatch => {
	const url = `/infrastructures/${infrastructureId}/tables`;

	return axios.get(url, {
		params:{
			date:date,
		}
	}).then(response => {
		dispatch({
			type: FETCH_TABLES,
			payload: response.data,
		});
		dispatch({
			type: ADD_INFRASTRUCTURE_MENU_ITEMS,
			payload: response.data.map(table=> table.table_name),
		});
	});
};