import React from 'react';
import {connect} from 'react-redux';
import {fetchCategoryItems} from '../../actions/categoryActions';
import {updateMenuItems} from '../../actions/menuItemActions';
import CategoryCards from './CategoryCards';
import CategoryHeader from './CategoryHeader';

class Category extends React.Component {

	fetchItems = () => {
		this.props.fetchCategoryItems(this.props.infrastructureId, this.props.selectedDateTag.date, this.props.type);
	};

	componentDidMount() {
		this.fetchItems();
		this.props.updateMenuItems(this.props.type, this.props.menuItems);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.fetchItems();
			this.props.updateMenuItems(this.props.type, this.props.menuItems);
		}
	}

	render() {
		return (
			<div>
				<CategoryHeader type={this.props.type}/>
				<CategoryCards type={this.props.type}/>
			</div>
		);
	}
}

const mapStateToProps = ({menuItems, selectedInfrastructure , selectedDateTag}, ownProps) => {
	return {
		infrastructureId: selectedInfrastructure.id,
		type: ownProps.match.params.category,
		menuItems: Object.keys(menuItems),
		selectedDateTag: selectedDateTag,
	};
};

export default connect(mapStateToProps, {fetchCategoryItems, updateMenuItems})(Category);