import React, {Component} from 'react';
import {connect} from 'react-redux';
import {initialize} from 'redux-form';
import selectRepository from '../../selectors/selectRepository';
import {
	deleteRepositoryItem,
	getAllRepositoryItems,
	selectRepositoryItem
} from '../../actions/Repository/RepositoryItemActions';
import RepositoryItemsComponent from './RepositoryItemsComponent';
import moment from 'moment';
import {openInNewTab} from '../../utils';

class RepositoryItems extends Component {
	componentDidMount() {
		this.props.getAllRepositoryItems(this.props.repository._id);
	}

	componentDidUpdate(prevProps) {
		if (this.props.repository._id !== prevProps.repository._id) {
			this.props.getAllRepositoryItems( this.props.repository._id );
		}
	}
	handleShowCreateForm = () => {
		window.$('#repository-item-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};
	handleShowUpdateForm = (repositoryItem) => {
		repositoryItem = Object.assign({}, repositoryItem, {
			date: moment(repositoryItem.date).zone(+0).format('MMMM DD,YYYY')
		});
		this.props.initializeRepositoryItemForm(repositoryItem);
		window.$('#repository-item-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};
	handleDelete = (repositoryItem) => {
		this.props.deleteRepositoryItem(this.props.repository._id, repositoryItem._id);
	};

	handleSelectRepository = (item) => {
		this.props.selectRepositoryItem(item);
	};

	handleDownload = (item) => {
		const pathForRepositoryItem = `/${moment(new Date(item.date)).zone(+0).format('YYYY-MM-DD')}-${item.camera}-${item.producer}-(${item._id})`;
		openInNewTab(`http://localhost:8080/apis/repositories/${this.props.repository._id}/download-folder?directory_path=${pathForRepositoryItem}`);
	};

	render() {
		return (
			<RepositoryItemsComponent
				repository={this.props.repository}
				onDeleteRepositoryItem={this.handleDelete}
				onShowCreateForm={this.handleShowCreateForm}
				onShowUpdateForm={this.handleShowUpdateForm}
				onSelectRepository={this.handleSelectRepository}
				onDonwload={this.handleDownload}
			/>
		);
	}
}

const mapStateToProps = state => ({
	repository: selectRepository(state),
});
const mapDispatchToProps = dispatch => ({
	getAllRepositoryItems: (id) => dispatch(getAllRepositoryItems(id)),
	initializeRepositoryItemForm: (initialForm) => dispatch(initialize('repository-item', initialForm)),
	deleteRepositoryItem: (repositoryId , id) => dispatch(deleteRepositoryItem(repositoryId, id)),
	selectRepositoryItem: (item) => dispatch(selectRepositoryItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps) (RepositoryItems);
