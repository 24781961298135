import React from 'react';
import {connect} from 'react-redux';
import Card from '../Utility/Card';
import {FILTER_SHOW_ALL} from '../../utils';

import {fetchCategoryItemDetails} from '../../actions/categoryActions';

class CategoryCards extends React.Component {
	onCardClick = (categoryId) => {
		this.props.fetchCategoryItemDetails(categoryId);
	};

	filterCategoryItems = (item) => {
		return item.subtype === this.props.selectedFilter || !this.props.selectedFilter || this.props.selectedFilter === FILTER_SHOW_ALL;
	};

	render() {
		if (!this.props.categoryItems) {
			return null;
		}

		const categoryCards = Object.values(this.props.categoryItems)
			.filter(this.filterCategoryItems)
			.map(item => {
				return (
					<Card key={item._id} id={item._id} onHover={() => ({})} onClick={this.onCardClick}
						  redirectURL={`/infrastructures/${this.props.selectedInfrastructureId}/category/${this.props.type}/${item._id}`}
						  title={item.name} description={item.description} img={item.image_url.small} fullImageURL={item.image_url.full}
					/>
				);
			});

		return (
			<div className='ui left aligned basic segment'>
				<div className='ui stackable cards'>
					{categoryCards}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({selectedInfrastructure}, ownProps) => {
	const {items, selectedFilter} = selectedInfrastructure.categories[ownProps.type];
	return {
		selectedInfrastructureId: selectedInfrastructure.id,
		categoryItems: items,
		selectedFilter
	};
};

export default connect(mapStateToProps, {fetchCategoryItemDetails})(CategoryCards);