import axios from '../../apis/apiclient';
import
{
	ADD_INFRASTRUCTURE_MENU_ITEMS,
	Date_TAG_SELECT, DATE_TAG_UNSELECT,
	DATE_TAGS_FAILED,
	DATE_TAGS_RECEIVED,
	DATE_TAGS_REQUESTED
} from '../types';
import moment from 'moment';

export const fetchDateTags = (infrastructureId) => async dispatch => {
	const url = `/infrastructures/${infrastructureId}/date-tags`;
	dispatch({
		type: DATE_TAGS_REQUESTED,
	});

	return axios.get(url)
		.then(response => dispatch({
			type: DATE_TAGS_RECEIVED,
			payload: response.data,
		}))
		.catch(err => dispatch({
			type: DATE_TAGS_FAILED,
			errorMessage: err.response.errorMessage,
		}));
};

export const selectDateTag = (date) => dispatch => {
	dispatch({
		type: Date_TAG_SELECT,
		payload: date,
	});
	dispatch({
		type: ADD_INFRASTRUCTURE_MENU_ITEMS,
		payload: [moment(date).format('MMMM Do YYYY').toLocaleLowerCase()],
	});
};

export const unselectDateTag = () => dispatch => {
	dispatch({
		type: DATE_TAG_UNSELECT,
	});
};