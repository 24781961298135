import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loginRepository} from '../../actions/authRepositoryActions';

class RepositoryLogin extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	onFormSubmit = (event) => {
		event.preventDefault();
		this.props.loginRepository({repository_password: this.state.password});
	};

	renderDefault() {
		return (
			<div className='ui one column stackable grid repository-login'>
				<div className='column centered floated five wide'>
					<div className='ui center aligned grid'>
						<div className='column ten wide'>
							<i className="huge database book icon"/>
							<h1 className='ui center aligned header p-b-20'>
								Login To Repository
							</h1>
							<form className='ui form' onSubmit={this.onFormSubmit}>
								<div className='field'>
									<input className='input' type='password' name='password' placeholder='Password'
										   onChange={e => this.setState({password: e.target.value})}/>
								</div>
								<button className='ui inverted primary button' type='submit'
									style={{marginTop: '10px'}}>Submit
								</button>
								<div className='field'>
									<p className={`error ${this.props.loginError ? 'visible' : 'hidden'}`}>{this.props.loginError}</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
	render() {
		if (this.props.isLoggedIn) {
			return null;
		}
		return this.renderDefault();
	}
}


const mapStateToProps = ({authRepository}) => {
	return {isLoggedIn: authRepository.isLoggedIn, loginError: authRepository.loginError ? authRepository.loginError.message : null};
};

export default connect(mapStateToProps, {loginRepository})(RepositoryLogin);
