import React from 'react';
import {connect} from 'react-redux';

class SidebarNavMenuItem extends React.Component {

	render() {
		if (!this.props.item) {
			return null;
		}

		return (
			<div className={`item ${this.props.item.isActive ? 'active' : ''}`}
				 onClick={() => this.props.onClicked(this.props.name)}>
				{this.props.name}
			</div>
		);
	}
}

const mapStateToProps = ({menuItems}, ownProps) => {
	return {item: menuItems[ownProps.name.toLowerCase()]};
};

export default connect(mapStateToProps)(SidebarNavMenuItem);
