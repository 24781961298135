import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SidebarNavMenuItem from './SidebarNavMenuItem';
import {capitalize} from '../../utils';
import moment from 'moment';

class SidebarNavInfrastructureMenu extends React.Component {

	render() {
		if (!this.props.categories) {
			return null;
		}

		const categories = this.props.categories.map(category => {
			return (
				<Link key={category}
					  to={`/infrastructures/${this.props.infrastructureId}/category/${category}`}>
					<SidebarNavMenuItem name={capitalize(category)}
						onClicked={this.props.onItemClicked}/>
				</Link>
			);
		});

		const tables = this.props.tables.map(table =>
			<Link
				key={table._id}
				to={`/infrastructures/${this.props.infrastructureId}/table/${table._id}`}
			>
				<SidebarNavMenuItem
					name={capitalize(table.table_name)}
					onClicked={this.props.onItemClicked}
				/>
			</Link>
		);

		return (
			<div>
				<Link to={`/infrastructures/${this.props.infrastructureId}`}>
					<SidebarNavMenuItem name={`Tower ${this.props.infrastructureSiteCode}`}
						onClicked={this.props.onItemClicked}/>
				</Link>
				<Link to={`/infrastructures/${this.props.infrastructureId}`}>
					<SidebarNavMenuItem
						name={moment(this.props.selectedDateTag.date).format('MMMM Do YYYY')}
						onClicked={this.props.onItemClicked}
					/>
				</Link>
				{tables}
				{categories}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const infrastructure = state.infrastructures[state.selectedInfrastructure.id];
	return {
		categories: state.selectedInfrastructure.categories ? Object.keys(state.selectedInfrastructure.categories) : [],
		tables: state.table.tables,
		infrastructureId: state.selectedInfrastructure.id,
		infrastructureSiteCode: infrastructure ? infrastructure['site_code'] : null,
		selectedDateTag: state.selectedDateTag,
	};
};

export default connect(mapStateToProps)(SidebarNavInfrastructureMenu);