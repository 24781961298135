import React, {Component} from 'react';
import RemoteActions from '../Form/RemoteActions';
import RepositoryItemForm from '../Form/RepositoryItem/RepositoryItemForm';

class RepositoryItemModalForm extends Component {

	render() {
		return (
			<div id="repository-item-modal-form" className="ui modal">
				<div className="header">
					Add Item
				</div>
				<div className="content">
					<RepositoryItemForm
						repositoryId={this.props.repositoryId}
					/>
				</div>
				<div className="actions">
					<RemoteActions form="repository-item"/>
				</div>
			</div>
		);
	}
}

export default RepositoryItemModalForm;
