import React, {Component} from 'react';

class Dropdown extends Component {

	componentDidMount() {
		window.$('.selection.dropdown').dropdown();
	}

	handleChange = (value) => {
		this.props.input.onChange(value);
	};

	render() {
		const {
			input,
			label,
			data,
			meta: { touched, error, warning }
		} = this.props;
		return (
			<div className={'ui field ' + (touched && error ? 'error' : '')}>
				<label>{label}</label>
				<div className="ui selection dropdown">
					<input {...input} type="hidden" name="gender"/>
					<i className="dropdown icon"/>
					<div className={'text '+ (input.value ?'' : 'default')}>{input.value ? data.find(item => item.value === input.value).text : 'Select Owner'}</div>
					<div className="menu">
						{
							data.map(item =>
								<div  key={item.value} onClick={() => this.handleChange(item.value)} className="item" data-value={item.value}>{item.text}</div>
							)
						}
					</div>
				</div>
				{touched && warning && <div className="ui pointing yellow basic label">{warning}</div>}
				{touched && error && <div className="ui pointing red basic label">{error}</div>}
			</div>

		);
	}
}

export default Dropdown;