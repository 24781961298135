import React, {Component} from 'react';

class DatePicker extends Component {

	componentDidMount() {
		window.$('.ui.calendar.date-picker').calendar({
			type: 'date',
			on: 'click',
		});
	}

	render() {
		const {
			input,
			label,
			meta: { touched, error, warning }
		} = this.props;
		return (

			<div className={'ui field calendar date-picker ' + (touched && error ? 'error' : '')}>
				<label>{label}</label>
				<div className="ui input left icon">
					<i className="calendar icon"/>
					<input {...input} type="text"  placeholder={label}/>
				</div>
				{touched && warning && <div className="ui pointing yellow basic label">{warning}</div>}
				{touched && error && <div className="ui pointing red basic label">{error}</div>}
			</div>

		);
	}
}

export default DatePicker;