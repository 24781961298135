import {
	FETCH_INFRASTRUCTURES,
	INFRASTRUCTURE_HOVER_TOGGLE,
	SAVE_SELECTED_INFRASTRUCTURE,
	SAVE_INFRASTRUCTURE_CATEGORIES, ADD_INFRASTRUCTURE_MENU_ITEMS
} from './types';

import axios from '../apis/apiclient';

export const fetchInfrastructures = () => async dispatch => {
	const infrastructures = await axios.get('/infrastructures');

	dispatch({type: FETCH_INFRASTRUCTURES, payload: infrastructures.data});
};

export const onHover = (infrastructureId) => {
	return {type: INFRASTRUCTURE_HOVER_TOGGLE, payload: infrastructureId};
};

export const selectInfrastructure = (infrastructureId) => dispatch => {
	dispatch({type: SAVE_SELECTED_INFRASTRUCTURE, payload: infrastructureId});
};

export const listCategoryTypes = (infrastructureId, date) => async dispatch => {
	const url = `/infrastructures/${infrastructureId}/categories/types`;

	axios.get(url,
		{
			params:{
				date: date,
			}
		})
		.then(response => {
			dispatch({
				type: ADD_INFRASTRUCTURE_MENU_ITEMS,
				payload: response.data,
			});
			dispatch({
				type: SAVE_INFRASTRUCTURE_CATEGORIES,
				payload: response.data,
			});
		});
};