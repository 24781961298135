import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {ContextMenuTrigger} from 'react-contextmenu';
import RepositoryTableContextMenu from './RepositoryTableContextMenu';
import RepositoryModalForm from './RepositoryModalForm';

class RepositoriesComponent extends Component {
	render() {
		const {repositories, owners} = this.props;
		return (
			<div className="ui segment repositories">
				<RepositoryModalForm
					owners={owners}
				/>
				{repositories.isFetching &&
					<div className="ui active inverted dimmer">
						<div className="ui text large loader">Loading</div>
					</div>
				}
				{!repositories.isFetching && repositories.hasError &&
					<div className="ui warning message">
						<div className="header">
							{repositories.hasError.errorMessage}
						</div>
					</div>
				}
				{!repositories.isFetching && !repositories.hasError &&
					<>
						<button onClick={this.props.onShowCreateForm} className="ui positive button">
							Add Repository
						</button>
						<table id="repository-table" className="ui fixed single line table">
							<thead>
								<tr>
									<th style={{width:'50px'}} />
									<th>Date</th>
									<th>Site ID</th>
									<th>Type</th>
									<th>Owner</th>
								</tr>
							</thead>
							<tbody>
								{repositories.data.map(repository => {
									const menuId = 'repository-table-context-menu' + repository._id;
									return(
										<Fragment key={repository._id}>
											<RepositoryTableContextMenu
												id={menuId}
												onUpdate={() => this.props.onShowUpdateForm(repository)}
												onDelete={() => this.props.onDeleteRepository(repository)}
												onDownload={() => this.props.onDownloadRepository(repository)}
												repository={repository}
											/>
											<tr key={repository._id} onClick={() => this.props.onSelectRepository(repository._id)} >
												<td>
													<ContextMenuTrigger repoditory={repository._id}
														id={menuId}><i
															className="database icon"/></ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger repoditory={repository._id}
														id={menuId}>{moment(repository.date).zone(+0).format('MMMM Do YYYY')}</ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger repoditory={repository._id}
														id={menuId}>{repository.site_id}</ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger repoditory={repository._id}
														id={menuId}>{repository.type}</ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger repoditory={repository._id}
														id={menuId}>
														{
															owners.find(owner => owner.value = repository.owner)
																&& owners.find(owner => owner.value = repository.owner).text
														}
													</ContextMenuTrigger>
												</td>
											</tr>
										</Fragment>
									);
								}
								)}
							</tbody>
						</table>
					</>
				}
			</div>
		);
	}
}

export default RepositoriesComponent;
