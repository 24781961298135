import axios from '../../../apis/apiclient';
import { SubmissionError } from 'redux-form';
import {
	REPOSITORY_CREATED,
	REPOSITORY_UPDATED
} from '../../../actions/types';

export default (values, dispatch) => {
	if(values._id){
		const {_id, ...updatedValue} = values;
		const url = `repositories/${_id}`;
		return axios.put(url, updatedValue)
			.then(() => {
				dispatch({
					type: REPOSITORY_UPDATED,
					_id: _id,
					updatedValue: updatedValue,
				});
				window.$('#repository-modal-form.ui.modal').modal().modal('hide');
			})
			.catch(err => {
				const error = {};
				if(err.response.data)
					error._error = err.response.data;
				throw new SubmissionError(error);
			});
	}else{
		const url = 'repositories';
		return axios.post(url, values)
			.then(response => {
				dispatch({
					type: REPOSITORY_CREATED,
					payload: response.data,
				});
				window.$('#repository-modal-form.ui.modal').modal().modal('hide');
			})
			.catch(err => {
				const error = {};
				if(err.response.data)
					error._error = err.response.data;
				throw new SubmissionError(error);
			});
	}

};