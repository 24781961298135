import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import TextField from '../Fields/TextFields';
import DatePicker from '../Fields/DatePicker';
import Dropdown from '../Fields/Dropdown';
import validate from './validate';
import submit from './submit';

class RepositoryForm extends Component {

	render() {
		const {error} = this.props;
		return (
			<form className={'ui form '+ (error? 'error': '')}>
				<div className="two fields">
					<Field name="date" label="Date" component={DatePicker} type="text" />
					<Field name="site_id" label="Site ID" component={TextField} type="text" />
				</div>
				<div className="two fields">
					<Field name="type" label="Type" component={TextField} type="text" />
					<Field name="owner" label="Owner" data={this.props.owners} component={Dropdown} type="text" />
				</div>
				<div className="ui error message">
					<p>{error}</p>
				</div>
			</form>
		);
	}
}

export default reduxForm({
	form: 'repository',
	onSubmit: submit,
	validate,
})(RepositoryForm);

