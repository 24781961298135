import React from 'react';
import {connect} from 'react-redux';
import {updateCategoryFilters, updateSelectedFilter} from '../../actions/categoryActions';
import DropdownMenu from '../Utility/DropdownMenu';
import {FILTER_SHOW_ALL} from '../../utils';

class CategoryHeader extends React.Component {

	state = {isDropdownVisible: false};

	updateFilters = () => {
		const filters = Object.values(this.props.categoryItems).reduce((filters, item) => {
			if (!filters.includes(item['subtype'])) {
				filters.push(item['subtype']);
			}
			return filters;
		}, [FILTER_SHOW_ALL]);

		this.props.updateCategoryFilters(this.props.type, filters);
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.categoryItems && this.props.categoryItems) {
			this.updateFilters();
			this.setState({isDropdownVisible: false});
		}
	}

	onDropdownShow = (event) => {
		event.preventDefault();
		this.setState({isDropdownVisible: true}, () => {
			document.addEventListener('click', this.onDropdownHide);
		});
	};

	onDropdownHide = () => {
		this.setState({isDropdownVisible: false}, () => {
			document.removeEventListener('click', this.onDropdownHide);
		});
	};

	onDropdownItemClick = (item) => {
		this.props.updateSelectedFilter(this.props.type, item);
	};

	render() {
		return (
			<div className='ui right aligned basic segment'>
				<div
					className='ui labeled top right pointing dropdown icon button'
					onClick={this.onDropdownShow}>
					<span className="text">{this.props.selectedFilter || 'Filter'}</span>
					<i className='filter icon'/>
					<DropdownMenu items={this.props.filters} visibility={this.state.isDropdownVisible}
								  onItemClick={this.onDropdownItemClick}/>
				</div>
				<h4 className='ui horizontal divider'>
					{this.props.type}
				</h4>
			</div>
		);
	}
}

const mapStateToProps = ({selectedInfrastructure}, ownProps) => {
	const {filters, selectedFilter, items} = selectedInfrastructure.categories[ownProps.type];
	return {
		filters, selectedFilter,
		categoryItems: items
	};
};

export default connect(mapStateToProps, {updateCategoryFilters, updateSelectedFilter})(CategoryHeader);