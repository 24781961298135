import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import TextField from '../Fields/TextFields';
import DatePicker from '../Fields/DatePicker';
import validate from './validate';
import submit from './submit';

class RepositoryItemForm extends Component {

	render() {
		const {error} = this.props;
		return (
			<form className={'ui form '+ (error? 'error': '')}>
				<div className="three fields">
					<Field name="date" label="Date" component={DatePicker} type="text" />
					<Field name="camera" label="Camera" component={TextField} type="text" />
					<Field name="producer" label="Producer" component={TextField} type="text" />
				</div>
				<div className="ui error message">
					<p>{error}</p>
				</div>
			</form>
		);
	}
}

export default reduxForm({
	form: 'repository-item',
	onSubmit: submit,
	validate,
})(RepositoryItemForm);

