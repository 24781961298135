import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {resetRepositoryApp} from '../../actions/authRepositoryActions';

const RepositoryRoute = ({component: Component, authRepository, ...rest}) => {
	const isLoggedIn = authRepository.isLoggedIn;
	if (!isLoggedIn) {
		resetRepositoryApp();
	}

	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn ? (
					<Component {...props} />
				) : (
					<Redirect to={{pathname: '/login-repository', state: {from: props.location}}} />
				)
			}
		/>
	);
};

const mapStateToProps = ({authRepository}) => {
	return {authRepository};
};

export default connect(mapStateToProps)(RepositoryRoute);