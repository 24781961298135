import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
	deleteDirectory, deleteFile,
	fetchDirectoryList,
	fetchFilesList,
	uploadFile
} from '../../actions/Repository/repositoryFolderActions';
import RepositoryFolderComponent from '../../reducers/Repository/RepositoryFolderComponent';
import history from '../../history';
import qs from 'query-string';
import moment from 'moment';
import axios from '../../apis/apiclient';
import {openInNewTab} from "../../utils";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();


class RepositoryFolder extends Component {

	constructor(props){
		super(props);
		this.state={
			progress: 0,
			directory: qs.parse(props.location.search, { ignoreQueryPrefix: true }).directory,
		};
	}

	componentDidMount() {
		this.fetchDirectoryFolder();
	}

	componentDidUpdate(prevProps) {
		if(this.props.location.search !== prevProps.location.search){
			this.setState({
				directory: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).directory,
			}, () => this.fetchDirectoryFolder());
		}
	}

	fetchDirectoryFolder = () => {
		this.props.fetchDirectoryList(this.props.selectedRepository, this.props.selectedRepositoryItem._id, this.state.directory);
		this.props.fetchFilesList(this.props.selectedRepository, this.props.selectedRepositoryItem._id, this.state.directory);
	};

	handleSelectDirectory = (directory) => {
		history.push(`?directory=${this.state.directory}${directory}/`);
	};

	handleShowUploadFile = () => {
		window.$('#repository-add-directory-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};

	uploadZipFile = () => {
		if(!document.getElementById('file').files[0]) return;
		window.$('#percentage-modal').modal({}).modal('show');
		window.$('#progress').progress({
			percent:0,
		});
		this.setState({progress: 0});
		const item = this.props.selectedRepositoryItem;
		const pathForRepositoryItem = `${moment(new Date(item.date)).zone(+0).format('YYYY-MM-DD')}-${item.camera}-${item.producer}-(${item._id})`;
		const url = `/repositories/${this.props.selectedRepository}/upload-zip-file`;
		const data = new FormData();
		data.append('directory_path', `${pathForRepositoryItem}/${this.state.directory}`);
		data.append('file', document.getElementById('zipFile').files[0]);
		const config = {
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
				this.setState({uploadProgress: percentCompleted});
				window.$('#progress').progress({
					percent:percentCompleted,
				});
				this.setState({progress: percentCompleted});
			},
			cancelToken: source.token,
		};
		uploadFile(url, data, config, (err) => {
			if(!err)
				window.location.reload(true);
		});
	};

	uploadFile = () => {
		if(!document.getElementById('file').files[0]) return;
		window.$('#percentage-modal').modal({}).modal('show');
		window.$('#progress').progress({
			percent:0,
		});

		this.setState({progress: 0});
		const item = this.props.selectedRepositoryItem;
		const pathForRepositoryItem = `${moment(new Date(item.date)).zone(+0).format('YYYY-MM-DD')}-${item.camera}-${item.producer}-(${item._id})`;
		const url = `/repositories/${this.props.selectedRepository}/upload-file`;

		axios.post(url, {
			directory_path: `${pathForRepositoryItem}/${this.state.directory}`,
			file: document.getElementById('file').files[0].name,
			fileType: document.getElementById('file').files[0].type,
		}).then(res => {
			const signedUrl = res.data.signedUrl;
			const data = new FormData();
			data.append('directory_path', `${pathForRepositoryItem}/${this.state.directory}`);
			data.append('file', document.getElementById('file').files[0]);
			const config = {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
					this.setState({uploadProgress: percentCompleted});
					window.$('#progress').progress({
						percent:percentCompleted,
					});
					this.setState({progress: percentCompleted});
				},
				cancelToken: source.token,
				headers: {
					'Content-Type': document.getElementById('file').files[0].type,
				}
			};
			uploadFile(signedUrl, data, config, (err) => {
				if(!err)
					window.location.reload(true);
			});
		});
	};

	handleCancelUpload = () => {
		source.cancel('Operation canceled by the user.');
	};

	handleDownloadDirectory = (directory) => {
		const item = this.props.selectedRepositoryItem;
		const pathForRepositoryItem = `/${moment(new Date(item.date)).zone(+0).format('YYYY-MM-DD')}-${item.camera}-${item.producer}-(${item._id})`;
		const directoryPath = `${pathForRepositoryItem}/${this.state.directory}${directory}`;
		openInNewTab(`https://portal.definitechs.com/apis/repositories/${this.props.selectedRepository}/download-folder?directory_path=${directoryPath}`);
	};

	handleDeleteDirectory = (directory) => {
		this.props.deleteDirectory(this.props.selectedRepository, this.props.selectedRepositoryItem._id, this.state.directory, directory);
	};

	handleDeleteFile = (file) => {
		this.props.deleteFile(this.props.selectedRepository, this.props.selectedRepositoryItem._id, this.state.directory, file);
	};

	handleDownloadFile = (file) => {
		const item = this.props.selectedRepositoryItem;
		const pathForRepositoryItem = `/${moment(new Date(item.date)).zone(+0).format('YYYY-MM-DD')}-${item.camera}-${item.producer}-(${item._id})`;
		const directoryPath = `${pathForRepositoryItem}/${this.state.directory}${file.Key}`;
		openInNewTab(`https://portal.definitechs.com/apis/repositories/${this.props.selectedRepository}/download-file?directory_path=${directoryPath}&file_name=${file.Key}`);
	};

	render() {
		return (
			<RepositoryFolderComponent
				files={this.props.files}
				directory={this.state.directory}
				directories={this.props.directories}
				selectedRepository={this.props.selectedRepository}
				selectedRepositoryItem={this.props.selectedRepositoryItem}
				onSelectDirectory={this.handleSelectDirectory}
				onShowUploadFile={this.handleShowUploadFile}
				uploadZipFile={this.uploadZipFile}
				uploadFile={this.uploadFile}
				uploadProgress={this.state.progress}
				onCancelUpload={this.handleCancelUpload}
				onDownloadDirectory={this.handleDownloadDirectory}
				onDeleteDirectory={this.handleDeleteDirectory}
				onDeleteFile={this.handleDeleteFile}
				onDownloadFile={this.handleDownloadFile}
			/>
		);
	}
}

const mapStateToProps = state => ({
	files: state.repositoryFolder.files,
	directories: state.repositoryFolder.directories,
	selectedRepository: state.repositories.selectedRepository,
	selectedRepositoryItem: state.repositories.selectedItem,
});

const mapDispatchToProps = dispatch => ({
	fetchDirectoryList: (repositoryId, itemId,  directoryPrefix) => dispatch(fetchDirectoryList(repositoryId, itemId, directoryPrefix)),
	fetchFilesList: (repositoryId, itemId,  directoryPrefix) => dispatch(fetchFilesList(repositoryId, itemId, directoryPrefix)),
	deleteDirectory: (repositoryId, itemId, path, directory) => dispatch(deleteDirectory(repositoryId, itemId, path, directory)),
	deleteFile: (repositoryId, itemId, path, directory) => dispatch(deleteFile(repositoryId, itemId, path, directory))
});

export default connect(mapStateToProps, mapDispatchToProps) (RepositoryFolder);
