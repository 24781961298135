import {
	FETCH_ACCESSIBLE_OWNER_REQUESTED,
	FETCH_ACCESSIBLE_OWNER_RECEIVED,
	FETCH_ACCESSIBLE_OWNER_FAILURE,
} from '../types';
import axios from '../../apis/apiclient';

export const getAllAccessibleOwner = () =>  dispatch => {
	dispatch({
		type: FETCH_ACCESSIBLE_OWNER_REQUESTED,
	});

	axios.get('/repositories/accessible-owner')
		.then(response => {
			dispatch({
				type: FETCH_ACCESSIBLE_OWNER_RECEIVED,
				payload: response.data,
			});
		})
		.catch(err => {
			dispatch({
				type: FETCH_ACCESSIBLE_OWNER_FAILURE,
				errorMessage: err.response.data,
			});
		});
};
