import React from 'react';
import {connect} from 'react-redux';
import {openInNewTab} from '../utils';



const Header = (props) => {
	return (
		<div id='app-header'>
			<div className='ui large borderless menu'>
				<div className='sidebar item' onClick={props.onSidebarButtonClick}>
					<i className='sidebar icon'/>
				</div>
				<div className='item'>Definitechs</div>
				<div className='right menu'>
					{
						(props.selectedInfrastructure.id && props.selectedDateTag.isSelected) &&
						// eslint-disable-next-line
						<a
							className='item'
							onClick={()=>openInNewTab(`/apis/report?infrastructure_id=${props.selectedInfrastructure.id}&date=${props.selectedDateTag.date}`)}
						>
							<i className="file icon"/>Report
						</a>
					}
					<a
						className='ui item'
						href="/repositories"
					>
						<i className="database icon"/>Repository
					</a>
					<div className='dropdown-menu'>
						<div className='ui dropdown item'>
							<i className='building outline icon'/>
							{props.companyName}
							<div className='menu'>
								<div className='item' onClick={props.onLogout}>Logout</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

//TODO pass mapStateToProps to connect function
const mapStateToProps = ({user, selectedInfrastructure, selectedDateTag}) => {
	return {
		companyName: user.company_name,
		selectedInfrastructure: selectedInfrastructure,
		selectedDateTag: selectedDateTag
	};
};

export default connect(mapStateToProps)(Header);