import React from 'react';

const CardHeader = (props) => {
	if (!props.image) {
		return null;
	}
	
	return (
		<div className='ui image'>
			<img src={props.image} alt='card_image'/>
		</div>
	);
};

export default CardHeader;