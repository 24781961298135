import _ from 'lodash';
import {
	SET_LOGIN_STATUS,
	SET_LOGIN_ERROR,
	RESET_APP
} from './types';

import axios from '../apis/apiclient';
import {saveUserProfile} from './userActions';

import history from '../history';

export const login = body => dispatch => {

	axios.post('/login', body)
		.then(response => {
			if (response.data.success) {
				dispatch(setLoginStatus(true));
				const userProfile = _.omit(response.data, 'success');
				dispatch(saveUserProfile(userProfile));
				history.push('/');
			}
		})
		.catch(err => {
			dispatch(setLoginError(err.response.data || {message: 'Something went wrong'}));
		});
};

export const logout = () => async dispatch => {
	await axios.post('/logout');

	dispatch(setLoginStatus(false));
	dispatch(resetApp());
};

export const resetApp = () => ({type: RESET_APP});

export const setLoginStatus = (isLoggedIn = false) => ({
	type: SET_LOGIN_STATUS,
	payload: isLoggedIn
});

const setLoginError = (loginError = null) => ({
	type: SET_LOGIN_ERROR,
	payload: loginError
});




