import { get, set } from 'lodash';

export default (values) => {
	const errors = {};
	const requiredFields = ['date', 'camera', 'producer'];

	requiredFields.forEach((field) => {
		if (!get(values, field) && get(values, field) !== 0) {
			set(errors, field, 'Required');
		}
	});
	return errors;
};