import React, {Component} from 'react';
import {connect} from 'react-redux';
import { initialize } from 'redux-form';
import RepositoriesComponent from './RepositoriesComponent';
import {deleteRepository, getAllRepositories, selectRepository} from '../../actions/Repository/RepositoryActions';
import {getAllAccessibleOwner} from '../../actions/Repository/accessibleOwnerActions';
import moment from 'moment';
import {openInNewTab} from '../../utils';


class Repositories extends Component {

	componentDidMount() {
		this.props.getAllRepositories();
		this.props.getAllAccessibleOwner();
	}

	getOwners = () =>
		this.props.owners.isFetching ? [] :
			this.props.owners.data.map(owner => ({
				text: owner.username,
				value: owner._id,
			}));

	handleShowCreateForm = () => {
		window.$('#repository-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};
	handleShowUpdateForm = (repository) => {
		repository = Object.assign({}, repository, {
			date: moment(repository.date).zone(+0).format('MMMM DD,YYYY')
		});
		this.props.initializeRepositoryForm(repository);
		window.$('#repository-modal-form.ui.modal').modal({
			onApprove : () => false,
			onDeny: () => false,
		}).modal('show');
	};
	handleDelete = (repository) => {
		this.props.deleteRepository(repository._id);
	};
	handleDownload = (repository) => {
		openInNewTab(`http://localhost:8080/apis/repositories/${repository._id}/download-folder?directory_path=`);
	};
	render() {
		return (
			<RepositoriesComponent
				repositories={this.props.repositories}
				owners={this.getOwners()}
				onDeleteRepository={this.handleDelete}
				onShowCreateForm={this.handleShowCreateForm}
				onShowUpdateForm={this.handleShowUpdateForm}
				onSelectRepository={this.props.selectRepository}
				onDownloadRepository={this.handleDownload}
			/>
		);
	}
}

const mapStateToProps = state => ({
	repositories: state.repositories,
	owners: state.accessibleOwners,
});

const mapDispatchToProps = dispatch => ({
	getAllRepositories: () => dispatch(getAllRepositories()),
	getAllAccessibleOwner: () => dispatch(getAllAccessibleOwner()),
	deleteRepository: (id) => dispatch(deleteRepository(id)),
	initializeRepositoryForm: (initialForm) => dispatch(initialize('repository', initialForm)),
	selectRepository: (id) => dispatch(selectRepository(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Repositories);
