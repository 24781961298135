import React, {Component} from 'react';
import RemoteActions from '../Form/RemoteActions';
import RepositoryAddDirectoryForm from '../Form/RepositoryAddDirectory/RepositoryAddDirectoryForm';

class RepositoryAddDirectoryModalForm extends Component {

	render() {
		return (
			<div id="repository-add-directory-modal-form" className="ui small modal">
				<div className="header">
					Add Directory
				</div>
				<div className="content">
					<RepositoryAddDirectoryForm
						repositoryId={this.props.repositoryId}
						itemId={this.props.itemId}
						directory={this.props.directory}
					/>
				</div>
				<div className="actions">
					<RemoteActions form="repository-add-directory"/>
				</div>
			</div>
		);
	}
}

export default RepositoryAddDirectoryModalForm;
