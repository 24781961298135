import React from 'react';
import {connect} from 'react-redux';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import {onHover, selectInfrastructure} from '../../actions/infrastructureActions';

import InfrastructureIcon from '../../assets/infrastructure-default.png';
import InfrastructureHighlighted from '../../assets/infrastructure.png';

import InfrastructureCards from './InfrastructureCards';
import history from '../../history';
import {updateMenuItems} from '../../actions/menuItemActions';
import {unselectDateTag} from '../../actions/dateTagActions/dateTag';

const InfrastructuresMap = withScriptjs(
	withGoogleMap(props => {
		const centroidPosition = {lat: 0, lng: 0};

		const onInfrastructureClick = (infrastructureId) => {
			const infrastructureMenuItem = `tower ${props.infrastructuresObj[infrastructureId].site_code.toLowerCase()}`;
			let menuItems = ['profile', 'infrastructures', infrastructureMenuItem];

			props.updateMenuItems(infrastructureMenuItem, menuItems);
			history.push(`/infrastructures/${infrastructureId}`);
			props.selectInfrastructure(infrastructureId);
			props.unselectDateTag();
		};

		const markers = props.infrastructures.map(infrastructure => {
			centroidPosition.lat += infrastructure.location.coordinates[1];
			centroidPosition.lng += infrastructure.location.coordinates[0];

			return (
				<Marker
					position={{
						lng: infrastructure.location.coordinates[0],
						lat: infrastructure.location.coordinates[1]
					}}
					key={infrastructure._id}
					icon={infrastructure.isHovered ? InfrastructureHighlighted : InfrastructureIcon}
					onMouseOver={() => props.onHover(infrastructure._id)}
					onMouseOut={() => props.onHover(infrastructure._id)}
					onClick={() => onInfrastructureClick(infrastructure._id)}
				/>
			);
		});

		centroidPosition.lat = centroidPosition.lat / markers.length;
		centroidPosition.lng = centroidPosition.lng / markers.length;
		const defaultZoom = (window.innerWidth > 1200 ? 9 : window.innerWidth > 576 ? 8 : 7);

		return (
			<div>
				<GoogleMap defaultZoom={defaultZoom} center={centroidPosition}>{markers}</GoogleMap>
				<InfrastructureCards
					onInfrastructureClick={onInfrastructureClick}
				/>
			</div>
		);
	})
);

const mapStateToProps = ({infrastructures}) => {
	return {
		infrastructures: Object.values(infrastructures),
		infrastructuresObj: infrastructures,
	};
};

export default connect(mapStateToProps, {onHover ,updateMenuItems, selectInfrastructure , unselectDateTag})(InfrastructuresMap);
