import React, {Component} from 'react';
import {connect} from 'react-redux';
import DateTagSelectionComponent from './DateTagSelectionComponent';

import {fetchDateTags} from '../../actions/dateTagActions/dateTag';
import {selectDateTag} from '../../actions/dateTagActions/dateTag';
import {listCategoryTypes} from '../../actions/infrastructureActions';
import {fetchTables} from '../../actions/tableActions';
import {changeSidebarVisibility} from '../../actions/sidebar';

class DateTagSelection extends Component {

	constructor(props){
		super(props);
		this.state = {
			infrastructureId: props.infrastructureId,
		};
	}

	componentDidMount() {
		this.props.fetchDateTags(this.props.infrastructureId);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.infrastructureId !== prevState.infrastructureId) {
			nextProps.fetchDateTags(nextProps.infrastructureId);
			return {
				infrastructureId: nextProps.infrastructureId
			};
		}
		return null;
	}

	openSidebar = () => {
		this.props.changeSidebarVisibility(true);
	};

	handleSelectDateTag = (date) => {
		this.props.selectDateTag(date);
		this.openSidebar();
		this.props.listCategoryTypes(this.props.infrastructureId, date);
		this.props.fetchTables(this.props.infrastructureId, date);
	};

	render() {
		const dateTags = this.props.dateTags;
		const selectedDateTag = this.props.selectedDateTag;
		return (
			<DateTagSelectionComponent
				dateTags={dateTags}
				selectedDateTag={selectedDateTag}
				selectDateTag={this.handleSelectDateTag}
			/>
		);
	}
}

const mapStateToProps = ({selectedInfrastructure, dateTags, selectedDateTag}) => ({
	infrastructureId: selectedInfrastructure.id,
	dateTags: dateTags,
	selectedDateTag: selectedDateTag,
});

const mapDispatchToProps = dispatch => ({
	fetchDateTags: (infrastructureId) => dispatch(fetchDateTags(infrastructureId)),
	selectDateTag: (date) => dispatch(selectDateTag(date)),
	listCategoryTypes: (infrastructureId, date) => dispatch(listCategoryTypes(infrastructureId, date)),
	fetchTables: (infrastructureId, date) => dispatch(fetchTables(infrastructureId, date)),
	changeSidebarVisibility: (visibility) => dispatch(changeSidebarVisibility(visibility)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTagSelection);
