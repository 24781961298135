import {
	FETCH_REPOSITORIES_REQUESTED,
	FETCH_REPOSITORIES_RECEIVED,
	FETCH_REPOSITORIES_FAILURE,
	REPOSITORY_DELETED,
	REPOSITORY_SELECTED,
} from '../types';
import axios from '../../apis/apiclient';
import history from '../../history';

export const getAllRepositories = () =>  dispatch => {
	dispatch({
		type: FETCH_REPOSITORIES_REQUESTED,
	});

	axios.get('/repositories')
		.then(response => {
			dispatch({
				type: FETCH_REPOSITORIES_RECEIVED,
				payload: response.data,
			});
		})
		.catch(err => {
			dispatch({
				type: FETCH_REPOSITORIES_FAILURE,
				errorMessage: err.response.data,
			});
		});
};

export const deleteRepository = (id) => dispatch => {
	const url = `/repositories/${id}`;
	axios.delete(url)
		.then(() => {
			dispatch({
				type: REPOSITORY_DELETED,
				_id: id,
			});
		});
};

export const selectRepository = id => dispatch => {
	dispatch({
		type: REPOSITORY_SELECTED,
		id: id,
	});
	history.push(`/repositories/${id}/`);
};