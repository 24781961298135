import axios from '../../../apis/apiclient';
import { SubmissionError } from 'redux-form';
import {
	REPOSITORY_ITEM_CREATED,
	REPOSITORY_ITEM_UPDATED
} from '../../../actions/types';

export default (values, dispatch, props) => {
	if(values._id){
		const {_id, ...updatedValue} = values;
		const url = `repositories/${props.repositoryId}/items/${_id}`;
		return axios.put(url, updatedValue)
			.then(() => {
				dispatch({
					type: REPOSITORY_ITEM_UPDATED,
					_id: _id,
					repository_id: props.repositoryId,
					updatedValue: updatedValue,
				});
				window.$('#repository-item-modal-form.ui.modal').modal().modal('hide');
			})
			.catch(err => {
				const error = {};
				if(err.response.data)
					error._error = err.response.data;
				throw new SubmissionError(error);
			});
	}else{
		const url = `repositories/${props.repositoryId}/items/`;
		return axios.post(url, values)
			.then(response => {
				dispatch({
					type: REPOSITORY_ITEM_CREATED,
					repository_id: props.repositoryId,
					payload: response.data,
				});
				window.$('#repository-item-modal-form.ui.modal').modal().modal('hide');
			})
			.catch(err => {
				const error = {};
				if(err.response.data)
					error._error = err.response.data;
				throw new SubmissionError(error);
			});
	}

};