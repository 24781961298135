import React from 'react';
import {connect} from 'react-redux';
import Table from '../Utility/Table';
import {updateMenuItems} from '../../actions/menuItemActions';
import DateTagSelection from '../dateTag/DateTagSelection';

class InfrastructureDetail extends React.Component {

	getInfrastructureDetailItems = () => {
		return Object.keys(this.props.infrastructure).reduce((detailItems, item) => {
			if (!item.startsWith('is') && !item.startsWith('_') && item !== 'created' && item !== 'user_id') {
				if (item === 'location') {
					detailItems.push(['Site Address', this.props.infrastructure.location['address']]);
					detailItems.push(['Coordinates', this.props.infrastructure.location['coordinates'].join(', ')]);
				} else {
					if (item.includes('_')) {
						const propertyName = item.split('_')
							.map(splitItem => [splitItem[0].toUpperCase(), splitItem.slice(1)].join(''))
							.join(' ');
						detailItems.push([propertyName, this.props.infrastructure[item]]);
					} else {
						detailItems.push([item, this.props.infrastructure[item]]);
					}
				}
			}
			return detailItems;
		}, []);
	};

	componentDidMount() {
		this.props.updateMenuItems(this.props.type, this.props.menuItems);
	}

	render() {
		if (!this.props.infrastructure) {
			return null;
		}

		const detailItems = this.getInfrastructureDetailItems();

		return (
			<div className='ui center aligned grid'>
				<Table rows={detailItems}/>
				<DateTagSelection/>
			</div>
		);
	}
}

const mapStateToProps = ({infrastructures, menuItems, selectedInfrastructure}) => {
	return {
		infrastructure: infrastructures[selectedInfrastructure.id],
		menuItems: Object.keys(menuItems)
	};
};

export default connect(mapStateToProps, {updateMenuItems})(InfrastructureDetail);