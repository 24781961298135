import React, {Component, Fragment} from 'react';
import {ContextMenuTrigger} from 'react-contextmenu';
import DirectoryContextMenu from '../../components/Repository/DirectoryContextMenu';
import RepositoryAddDirectoryModalForm from '../../components/Repository/RepositoryAddDirectoryModalForm';
import PercentageModal from '../../components/PercentageModal/PercentageModal';

class RepositoryFolderComponent extends Component {

	render() {
		const {directories, files} = this.props;
		return (
			<div className="ui segment repositories">
				{/*<label htmlFor="zipFile" className="ui labeled icon button">*/}
				{/*	<i className="upload icon"/>*/}
				{/*	Upload Zip File</label>*/}
				{/*<input type="file" id="zipFile" onChange={this.props.uploadZipFile} style={{display:'none'}}/>*/}
				<label htmlFor="file" className="ui labeled icon button">
					<i className="file icon"/>
						Upload File</label>
				<input type="file" id="file" onChange={this.props.uploadFile} style={{display:'none'}}/>
				<RepositoryAddDirectoryModalForm
					repositoryId={this.props.selectedRepository}
					itemId={this.props.selectedRepositoryItem._id}
					directory={this.props.directory}
				/>
				<PercentageModal
					uploadProgress={this.props.uploadProgress}
					onCancelUpload={this.props.onCancelUpload}
				/>
				{(directories.isFetching || files.isFetching) &&
					<div className="ui active inverted dimmer">
						<div className="ui text large loader">Loading</div>
					</div>
				}
				{(directories.hasError || files.hasError) &&
					<div className="ui warning message">
						<div className="header">
							There is some error in connecting to the server.
						</div>
					</div>
				}
				{(!directories.isFetching && !directories.hasError && !files.isFetching && !files.hasError) &&
					<>
						<button onClick={this.props.onShowUploadFile} className="ui positive button">
							Add Directory
						</button>
						<table id="repository-table" className="ui fixed single line table">
							<thead>
								<tr>
									<th style={{width:'50px'}} />
									<th>Name</th>
								</tr>
							</thead>
							<tbody>
								{directories.data.map(directory => {
									const menuId = 'directory-context-menu' + directory;
									return(
										<Fragment key={directory}>
											<DirectoryContextMenu
												id={menuId}
												onRename={() => this.props.onRenameDirectory(directory)}
												onDelete={() => this.props.onDeleteDirectory(directory)}
												onDownload={() => this.props.onDownloadDirectory(directory)}
											/>
											<tr key={directory} onClick={() => this.props.onSelectDirectory(directory)} >
												<td>
													<ContextMenuTrigger id={menuId}><i className="folder open icon"/></ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger id={menuId}>{directory}</ContextMenuTrigger>
												</td>
											</tr>
										</Fragment>
									);
								})}
								{files.data.map(file => {
									const menuId = 'directory-context-menu' + file.Key;
									return(
										<Fragment key={file.Key}>
											<DirectoryContextMenu
												id={menuId}
												onRename={() => this.props.onRenameFile(file)}
												onDelete={() => this.props.onDeleteFile(file)}
												onDownload={() => this.props.onDownloadFile(file)}
											/>
											<tr key={file.Key} onClick={() => this.props.onDownloadFile(file)} >
												<td>
													<ContextMenuTrigger id={menuId}><i className="file icon"/></ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger id={menuId}>{file.Key}</ContextMenuTrigger>
												</td>
											</tr>
										</Fragment>
									);
								})}
							</tbody>
						</table>
					</>
				}
			</div>
		);
	}
}

export default RepositoryFolderComponent;
