import React from 'react';
import {connect} from 'react-redux';

import TableComponent from './TableComponent';

import {fetchTableItems} from '../../actions/tableActions';


class TableContainer extends React.Component {

	componentDidMount() {
		const {table_id} = this.props.match.params;
		this.props.fetchTableItems(this.props.infrastructureId, table_id );
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.table_id !== prevProps.match.params.table_id) {
			const {table_id} = this.props.match.params;
			this.props.fetchTableItems(this.props.infrastructureId, table_id );
		}
	}

	render() {
		const {table_id} = this.props.match.params;
		const table = this.props.tables.find(table => table._id === table_id);
		return (
			<div className="section__header">
				<h4 className="ui header genera">{table.table_name}</h4>
				<TableComponent
					header={table ? table.header: []}
					rows={table? table.rows : []}
					tableName={table.table_name}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({selectedInfrastructure, table, selectedDateTag}) => {
	return {
		infrastructureId: selectedInfrastructure.id,
		tables: table.tables,
		selectedDateTag: selectedDateTag,
	};
};

export default connect(mapStateToProps, {fetchTableItems})(TableContainer);