import React from 'react';
import {Router, Switch, Route} from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dashboard';
import PrivateRoute from './Auth/PrivateRoute';
import history from '../history';

const App = () => {
	return (
		<div>
			<Router history={history}>
				<Switch>
					<>
						<PrivateRoute path="/" component={Dashboard}/>
						<Route path="/login" component={Login}/>
					</>
				</Switch>
			</Router>
		</div>
	);
};

export default App;