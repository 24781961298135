import {
	FETCH_REPOSITORY_ITEMS_REQUESTED,
	FETCH_REPOSITORY_ITEMS_RECEIVED,
	FETCH_REPOSITORY_ITEMS_FAILURE,
	REPOSITORY_ITEM_DELETED,
	REPOSITORY_ITEM_SELECTED,
} from '../types';

import axios from '../../apis/apiclient';
import history from '../../history';

export const getAllRepositoryItems = (repositoryId) => dispatch => {
	const url = `/repositories/${repositoryId}/items`;
	dispatch({
		type: FETCH_REPOSITORY_ITEMS_REQUESTED,
	});
	axios.get(url)
		.then(response => dispatch({
			type: FETCH_REPOSITORY_ITEMS_RECEIVED,
			payload: response.data,
		}))
		.catch(err => dispatch({
			type: FETCH_REPOSITORY_ITEMS_FAILURE,
			errorMessage: err.response,
		}));
};

export const deleteRepositoryItem = (repositoryId, id) => dispatch => {
	const url = `/repositories/${repositoryId}/items/${id}`;
	axios.delete(url)
		.then(() => {
			dispatch({
				type: REPOSITORY_ITEM_DELETED,
				_id: id,
			});
		});
};

export const selectRepositoryItem = item => dispatch => {
	dispatch({
		type: REPOSITORY_ITEM_SELECTED,
		payload: item,
	});
	history.push(`${item._id}?directory=`);
};