import React, {Component, Fragment} from 'react';
import {ContextMenuTrigger} from 'react-contextmenu';
import moment from 'moment';
import RepositoryItemsContextMenu from './RepositoryItemsContextMenu';
import RepositoryItemModalForm from './RepositoryItemModalForm';

class RepositoryItemsComponent extends Component {

	render() {
		const {repository} = this.props;
		return (
			<div className="ui segment repositories">
				<RepositoryItemModalForm
					repositoryId={repository._id}
				/>
				{repository.fetchingItems &&
					<div className="ui active inverted dimmer">
						<div className="ui text large loader">Loading</div>
					</div>
				}
				{!repository.fetchingItems && repository.fetchedError &&
					<div className="ui warning message">
						<div className="header">
							{repository.fetchedError}
						</div>
					</div>
				}
				{!repository.fetchingItems && !repository.fetchedError && repository.items &&
					<>
						<button onClick={this.props.onShowCreateForm} className="ui positive button">
							Add Item
						</button>
						<table id="repository-table" className="ui fixed single line table">
							<thead>
								<tr>
									<th style={{width:'50px'}} />
									<th>Date</th>
									<th>Camera</th>
									<th>Producer</th>
								</tr>
							</thead>
							<tbody>
								{repository.items.map(item => {
									const menuId = 'repository-table-context-menu' + item._id;
									return(
										<Fragment key={item._id}>
											<RepositoryItemsContextMenu
												id={menuId}
												onUpdate={() => this.props.onShowUpdateForm(item)}
												onDelete={() => this.props.onDeleteRepositoryItem(item)}
												onDownload={() => this.props.onDonwload(item)}
												repository={repository}
											/>
											<tr key={item._id} onClick={() => this.props.onSelectRepository(item)} >
												<td>
													<ContextMenuTrigger item={item._id}
														id={menuId}><i
															className="archive icon"/></ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger item={item._id}
														id={menuId}>{moment(item.date).zone(+0).format('MMMM Do YYYY')}</ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger item={item._id}
														id={menuId}>{item.camera}</ContextMenuTrigger>
												</td>
												<td>
													<ContextMenuTrigger item={item._id}
														id={menuId}>{item.producer}</ContextMenuTrigger>
												</td>
											</tr>
										</Fragment>
									);
								}
								)}
							</tbody>
						</table>
					</>
				}
			</div>
		);
	}
}

export default RepositoryItemsComponent;
