import React from 'react';

const NavigationArrow = (props) => {
	return (
		<div className='horizontal-nav-scroll' onClick={() => props.onClicked(props.type)}>
			<i className={`chevron large icon ${props.type}`}/>
		</div>
	);
};

export default NavigationArrow;