import React from 'react';
import {Link} from 'react-router-dom';
import CardHeader from './CardHeader';
import CardBody from './CardBody';

class Card extends React.Component {

	render() {
		return (
			<Link to={{pathname: this.props.redirectURL, state: {fullImageURL: this.props.fullImageURL}}}>
				<div className='ui card'
					 onMouseOver={() => this.props.onHover(this.props.id)}
					 onMouseOut={() => this.props.onHover(this.props.id)}
					 onClick={() => this.props.onClick(this.props.id)}>
					<CardHeader image={this.props.img}/>
					<CardBody title={this.props.title} description={this.props.description}/>
				</div>
			</Link>
		);
	}
}

export default Card;

