import React from 'react';

const CardBody = (props) => {
	return (
		<div className='content'>
			<div className='center aligned header'>{props.title}</div>
			<div className='center aligned description' style={{textAlign: 'center'}}>{props.description}</div>
		</div>
	);
};

export default CardBody;