import React from 'react';
import {generateKey} from '../../utils';

const renderTableRow = (header, row) => {
	const items = {};
	row.forEach(item => {
		items[item.key] = item.value;
	});

	return (header || []).map(key =>
		<td key={key}>
			{ (items[key]!== undefined) ? items[key] : '-'}
		</td>
	);
};

const TableComponent = ({header, rows}) => {
	return(
		<table className="ui celled striped table general_table">
			<thead>
				<tr>
					{(header || []).map(key => <th key={key}>{key}</th>)}
				</tr>
			</thead>
			<tbody>
				{(rows || []).map(row =>
					<tr key={generateKey('row')}>
						{renderTableRow(header, row)}
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default TableComponent;