import React from 'react';
import {Link} from 'react-router-dom';
import {updateMenuItems} from '../actions/menuItemActions';
import {connect} from 'react-redux';

class UserProfile extends React.Component {

	componentDidMount() {
		if (this.props.menuItems) {
			this.props.updateMenuItems('profile', this.props.menuItems);
		}
	}

	render() {
		return (
			<div className='ui container'>
				<div id='profile-image'>
					<img src={this.props.companyLogo} alt='profile_logo'/>
					<h2 id='profile-info-text'>The towers inspection is awaiting for you to be reviewed!</h2>
					<Link to='/infrastructures'>
						<button id='get-started' className='ui inverted primary button'>
							Get Started
						</button>
					</Link>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({user, menuItems}) => {
	return {
		menuItems: Object.keys(menuItems),
		companyName: user.company_name,
		companyLogo: user.company_logo
	};
};

export default connect(mapStateToProps, {updateMenuItems})(UserProfile);