import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DateTagSelectionComponent extends PureComponent {

	state = {
		dateSearchText: '',
	};

	handleSearchTextChange = (event) => {
		this.setState({
			dateSearchText: event.target.value,
		});
	};

	renderDates = (dateTags) => {
		const dateSearchText = this.state.dateSearchText;
		const searchedDate = dateTags.data.filter(date => date.toLowerCase().includes(dateSearchText.toLowerCase())
			|| moment(date).format('MMMM Do YYYY').toLowerCase().includes(dateSearchText.toLowerCase()));

		return searchedDate.map(date=>(
			<button
				key={date}
				className="ui compact labeled icon green button"
				onClick={() => this.props.selectDateTag(date)}
			>
				<i className="calendar icon"/>
				{moment(date).format('MMMM Do YYYY')}
			</button>
		));
	};

	render() {
		const dateTags = this.props.dateTags;
		const renderDates = this.renderDates;
		return (
			<div className="ui segment date-selection">
				<h4 className="ui small header">
					<i className="calendar alternate icon"/>
					<div className="content">
						Date Selection
						{this.props.selectedDateTag.isSelected &&
							<div className="sub header">{moment(this.props.selectedDateTag.date).format('MMMM Do YYYY')}</div>
						}
					</div>

				</h4>
				<div className="ui search date-selection__search-content">
					<div className="ui icon input">
						<input
							className="prompt"
							type="text" placeholder="search..."
							value={this.state.dateSearchText}
							onChange={this.handleSearchTextChange}
						/>
						<i className="search icon"/>
					</div>
				</div>
				<div className="ui">
					{
						renderDates(dateTags)
					}
				</div>
			</div>
		);
	}
}

DateTagSelectionComponent.propTypes = {
	dateTags: PropTypes.object.isRequired,
	selectedDateTag: PropTypes.object.isRequired,
	selectDateTag: PropTypes.func.isRequired,
};

export default DateTagSelectionComponent;
