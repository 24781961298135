import {
	SET_REPOSITORY_LOGIN_STATUS,
	SET_REPOSITORY_LOGIN_ERROR,
	RESET_LOGIN_REPOSITORY,
} from './types';

import axios from '../apis/apiclient';

import history from '../history';

export const loginRepository = body => dispatch => {

	axios.post('/login-repository', body)
		.then(response => {
			if (response.data.success) {
				dispatch(setLoginStatus(true));
				history.push('/repositories');
			}
		})
		.catch(err => {
			dispatch(setLoginError(err.response.data || {message: 'Something went wrong'}));
		});
};

export const logout = () => async dispatch => {
	await axios.post('/repository-logout');

	dispatch(setLoginStatus(false));
	dispatch(resetRepositoryApp());
};

export const resetRepositoryApp = () => ({type: RESET_LOGIN_REPOSITORY});

export const setLoginStatus = (isLoggedIn = false) => ({
	type: SET_REPOSITORY_LOGIN_STATUS,
	payload: isLoggedIn
});

const setLoginError = (loginError = null) => ({
	type: SET_REPOSITORY_LOGIN_ERROR,
	payload: loginError
});




