import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import SidebarNavInfrastructureMenu from './SidebarNavInfrastructureMenu';
import SidebarNavMenuItem from './SidebarNavMenuItem';
import {updateMenuItems} from '../../actions/menuItemActions';

class SidebarNav extends React.Component {

	onMenuItemClicked = (menuItem) => {
		this.props.updateMenuItems(menuItem.toLowerCase(), this.props.menuItems);
	};

	renderDefault() {
		return (
			<div className={`app-sidebar ${this.props.menuVisibility ? 'visibility' : ''}`}>
				<div className='ui visible sidebar inverted vertical menu'>
					<Link to='/'>
						<SidebarNavMenuItem name='Profile' onClicked={this.onMenuItemClicked}/>
					</Link>
					<Link to='/infrastructures'>
						<SidebarNavMenuItem name='Infrastructures' onClicked={this.onMenuItemClicked}/>
					</Link>
					<SidebarNavInfrastructureMenu onItemClicked={this.onMenuItemClicked}/>
				</div>
			</div>
		);
	}

	render() {
		return this.renderDefault();
	}
}

const mapStateToProps = ({menuItems}) => {
	return {menuItems: Object.keys(menuItems)};
};

export default connect(mapStateToProps, {updateMenuItems})(SidebarNav);